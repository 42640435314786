import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

const pages = [
  { name: "About Us", path: "#about" },
  { name: "Services", path: "#services" },
  { name: "Testmonials", path: "#testmonials" },
  { name: "Contact Us", path: "#contact" },
];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [activePage, setActivePage] = useState("");
  const [scrolled, setScrolled] = useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);

      // Check which section is in view
      const sections = pages.map((page) => document.querySelector(page.path));
      let currentPage = "";

      sections.forEach((section) => {
        if (section) {
          const { offsetTop, offsetHeight } = section;
          const sectionTop = offsetTop - 100; // Adjust for AppBar height
          const sectionBottom = sectionTop + offsetHeight;
          if (window.scrollY >= sectionTop && window.scrollY < sectionBottom) {
            currentPage = section.getAttribute("id");
          }
        }
      });

      setActivePage(`#${currentPage}`);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: scrolled ? "#2b098f" : "unset",
        transition: "background-color 0.3s ease",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1200,
        boxShadow: !scrolled ? "none" : "", // Remove the box shadow
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <a
            href="#home"
            onClick={(e) => {
              e.preventDefault();
              window.scrollTo({ top: 0, behavior: "smooth" });
              setActivePage("#home");
            }}
            style={{ textDecoration: "none" }}
          >
            <Box
              component="img"
              src="6l.png"
              alt="Global Web Labs Logo"
              sx={{
                display: { xs: "none", md: "flex" },
                height: 80,
                width: 290,
                marginBottom: "5px",
                objectFit: "cover",
                ml: -5,
              }}
            />
          </a>

          <a
            href="#home"
            onClick={(e) => {
              e.preventDefault();
              window.scrollTo({ top: 0, behavior: "smooth" });
              setActivePage("#home");
            }}
            style={{ textDecoration: "none" }}
          >
            <Box
              component="img"
              src="6l.png"
              alt="Global Web Labs Logo"
              sx={{
                display: { justifyContent: "center", xs: "flex", md: "none" },
                height: 60,
                width: 185,
                ml: -5,
                objectFit: "cover",
              }}
            />
          </a>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon sx={{ color: "white" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={() => {
                    const section = document.querySelector(page.path);
                    if (section) {
                      section.scrollIntoView({ behavior: "smooth" });
                    }
                    handleCloseNavMenu();
                    setActivePage(page.path);
                  }}
                  sx={{
                    borderBottom:
                      activePage === page.path ? "2px solid white" : "none",
                    borderRadius: 0,
                    fontWeight:600,
                  }}
                >
                  <Typography
                    textAlign="center"
                    color={activePage === page.path ? "#ccc" : "#2b098f"}
                  >
                    {page.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex", justifyContent: "flex-end" },
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => {
                  const section = document.querySelector(page.path);
                  if (section) {
                    section.scrollIntoView({ behavior: "smooth" });
                  }
                  setActivePage(page.path);
                }}
                sx={{
                  my: 2,
                  color: activePage === page.path ? "#ccc" : "white",
                  display: "block",
                  borderBottom:
                    activePage === page.path ? "2px solid white" : "none",
                  borderRadius: 0,
                  fontWeight:600,
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
